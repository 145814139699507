// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@foxglove/studio-base/styles/assets/SpaceGrotesk-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@foxglove/studio-base/styles/assets/SpaceGrotesk-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:SpaceGrotesk;src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2")}@font-face{font-family:SpaceGrotesk;src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");font-weight:700}
`, "",{"version":3,"sources":["webpack://./../../packages/studio-base/src/styles/assets/space-grotesk.css"],"names":[],"mappings":"AAAA,WACE,wBAAA,CACA,2DAAA,CAGF,WACE,wBAAA,CACA,2DAAA,CACA,eAAA","sourcesContent":["@font-face {\n  font-family: \"SpaceGrotesk\";\n  src: url(\"@foxglove/studio-base/styles/assets/SpaceGrotesk-Regular.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-family: \"SpaceGrotesk\";\n  src: url(\"@foxglove/studio-base/styles/assets/SpaceGrotesk-Bold.woff2\") format(\"woff2\");\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
